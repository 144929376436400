<template>
  <v-card v-if="assessment" outlined flat class="mb-4" :loading="loading">
    <v-card-title v-text="'Previous assessment'" />

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <!-- Last update -->
        <template #[`item.assessment_time`]="{ item }">
          {{ formatDate(item['assessment-time']) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'

export default {
  name: 'pibot-eprs-assessment-briefing',
  mixins: [eprs],
  data: () => ({
    headers: [
      { text: 'Type', value: 'assessment_type', cellClass: 'text-capitalize' },
      { text: 'Last update', value: 'assessment_time' }
    ]
  }),
  computed: {
    items () {
      return [{ ...this.assessment }]
    }
  },
  async beforeMount () {
    if (!this.isNext) return
    const incident = await this.getIncidentById(this.iid)
    const assessment = incident.assessments.find(a => a.assessment_id === this.aid) // [this.aid - 1]

    const data = _.merge(...assessment.data.map(o => ({ [o.key]: o.value })))

    this.assessment = {
      ...assessment,
      ...data
    }
  }
}
</script>
